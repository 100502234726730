<template>
    <b-modal        
        :id="'modalGlobal-'+index"
        dialog-class="modal-document td-block-msgs" 
        :body-class="'modal-body-'+index"
        @hidden="hideModal()"
        @shown="handleOpen(msgId,index)"
        :lazy="true"
        centered >                       
        <template #modal-header="{ close }">
            <div class="msg-title">
                <h5 class="modal-title">{{title}}</h5>
                <div class="cmd-title" v-if="numCmd.length > 1">
                    <i class="feather-file-text"></i>
                    <span>{{numCmd}}</span>
                </div>
            </div>
            <button type="button" aria-label="Close" class="close"  @click="close()"><i class="feather-x"></i></button>
        </template>
        <template #default>
            <div class="d-flex justify-content-center mb-3" v-if="loading">
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else 
                class="block-comments" 
                :id="'block-comments-'+index" 
                :ref="'ref-block-'+index" 
                >
                <div v-for="(item,index) in globalMsgs" :key="index">
                    <div v-for="(comment,index2) in item.answers" :key="index2">
                        <div :class="'comment-position-'+comment.id" :id="'comment-position-'+comment.id" :ref="'commentPosition'+comment.id">
                            <div :class="[comment.user.uuid == uuid ? 'right-position' : 'left-position']">
                                <div class="sender-name" v-if="comment.user.uuid != uuid">
                                {{comment.user.first_name}}
                                </div>
                                <div class="item-comment">
                                    <div class="icon-reply" v-if="comment.user.uuid != uuid" @click="reply(comment)"><i class="ri-reply-fill"></i></div>
                                    <div class="" v-if="comment.respond_to">
                                        <div>
                                            {{comment.respond_to.content}}
                                        </div>
                                        <div class="date-comment">
                                            <span>{{comment.respond_to.created_at|formatDate}} à <span>{{comment.respond_to.created_at|formatHeure}}</span></span>
                                        </div>
                                        <hr>
                                    </div>
                                    <p> {{comment.content}} </p>
                                    <span v-if="comment.attachment_document"
                                       @click="downloadDocs(comment.attachment_document.doc_url, comment.attachment_document.doc_name)" class="attachment-msg">
                                        <i class="feather-paperclip"></i> {{ comment.attachment_document.doc_name }}
                                    </span>
                                    <div class="msg-document">
                                        <div class="libelle" v-if="libelleDoc"><span>{{libelleDoc}}</span></div>
                                        <span class="date-comment" >{{comment.created_at|formatDate}} à <span>{{comment.created_at|formatHeure}}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>   
            </div>
        </template>
        <template #modal-footer>
            <form class="send-comment" @submit.prevent="replyForMsg(msgId,parentId,commentTextArea,title,index)">
                <div class="reply-parent" v-if="isReplying">
                    <i class="feather-x" @click="hideReplying"></i>
                    <div class="reply-parent__content">
                        {{replyText.content}}
                    </div>
                    <div class="date-comment">
                        <span class="date-comment" >
                            {{replyText.userFirstName}} {{ replyText.userLastName }}  {{ replyText.created_at | formatDate}} à {{ replyText.created_at | formatHeure}}
                        </span>
                    </div>
                </div>
                <textarea class="form-control form-control-lg comment-text" 
                @keydown.enter="handleClick"
                    placeholder="Ajouter un commentaire ..."
                    cols="3"
                    v-model="commentTextArea"
                    @input="linesCount"
                    v-bind:style="{height : commentTextareaHeught + 'px', overflow : overflowAction}"
                    :rows="textareaHeight"
                ></textarea>

                <label for="file" class="upload-file-discussion"> 
                    <i class="feather-paperclip"></i>
                </label>
                <input type="file" id="file" class="d-none" @input="uploadFile($event)" />
                <button  class="btn sent_msg_btn" type="submit">
                    <i class="ri-send-plane-fill feather-send"></i>
                </button>
            </form>
            <div v-if="file" class="file-name-badge">
                <span class="label-name"> {{ file.name }}</span>
                <i class="feather-x close-icon" @click="removeFile"></i>
            </div>
        </template>
    </b-modal>
</template>
<script>
import { apiUrl } from '../../constants/config';
import axios from "axios";
import { watch } from 'vue';
export default {
    name:"GlobalDiscussion",
    props:{
        index : {
            type: String
        },
        msgId: {
            type: Number
        },
        parentId: {
            type: Number
        },
        title: {
            type: String
        },
        document: {
            type: Number
        },
        refreshComments:{
            type: Boolean,
            default: false
        },
        selectedModal:{
            type: Number,
            default: null

        }
    },
    components:{
    },
    data() {
        return {
            loading:true,
            globalMsgs:[],
            uuid:this.$store.state.user.currentUser.uuid,
            commentTextArea:'',
            file:null,
            textareaHeight: [],
            commentTextareaHeught: "",
            overflowAction: "hidden",
            emptyTextarea:false,
            replyText:{
                content:'',
                created_at:'',
                title:'',
                userFirstName:'',
                userLastName:''
            },
            isReplying:false,
            numCmd:'',
            respond_to:null,
            libelleDoc:'',
            sending :false
        }
    },
    created(){
    },
    methods:{
        uploadFile(e){
            let file = e.target.files[0];
            if (!file) return false;
            this.file = file;
            e.target.value = null;
        },
        removeFile(){
            this.file= null;
        },
        // SEND MSG : ON CLICK KEYBOARD ENTER.
        handleClick(){
        let sendBtn =  document.getElementsByClassName("sent_msg_btn");
        if(this.commentTextArea!=''){
            sendBtn[0].click();   
        }
        },
        linesCount(e) {
            // this.$emit("refresh", false);
            let linesNumber = this.commentTextArea.split(/\r*\n/);
            this.textareaHeight = linesNumber.length;
            this.commentTextareaHeught =
            this.commentTextArea.length > 1 || linesNumber.length > 1
                ? e.target.scrollHeight
                : 60;
            if (this.commentTextareaHeught >= 130) {
             this.overflowAction = "visible";
            }
        },
        reply(msg){
            this.isReplying = true;
            this.replyText.content=msg.content;
            this.replyText.created_at=msg.created_at;
            this.replyText.title=msg.title;
            this.replyText.userFirstName=msg.user.first_name;
            this.replyText.userLastName=msg.user.last_name;
            this.respond_to=msg.id;
        },
        initialize(){
            this.replyText= {content:'',created_at:'',title:'',userFirstName:'',userLastName:''};
            this.isReplying = false;
            this.respond_to=null;
        },
        hideReplying(){
            this.isReplying = false;
        },
        replyForMsg(msgId,parentId,commentTextArea,title,index){

            const formData = new FormData();
            formData.append('parent_id', parentId);
            formData.append('content', commentTextArea);
            formData.append('title', title);
            formData.append('respond_to', this.respond_to);
            formData.append('email_copy', false);
            formData.append('file', this.file);

            if(commentTextArea || this.file){
                // let msg={
                //     parent_id:parentId,
                //     content:commentTextArea,
                //     title:title,
                //     respond_to:this.respond_to,
                //     email_copy:false,
                //     file: this.file
                // }
                this.sending =true ;
                axios.post(apiUrl +"/api/admin/v1/messages",formData)
                .then(response => {
                    if (response.status === 200 || response.status === 201) {
                        this.reloadComments(msgId);
                        this.scrollToBottom(index);  
                        this.emptyTextarea = true;
                        this.initialize();
                        this.commentTextArea='';
                        this.file= null;
                        this.linesCount();    
                        this.overflowAction= "hidden";                        
                        this.sending =false ;
                    }
                })
                .catch(err =>{
                    this.sending =false ;
                })
            }
        },
        hideModal(){
            this.moreComments = false;
            this.commentTextArea='';
            this.textareaHeight= [];
            this.commentTextareaHeught= "";
            this.overflowAction= "hidden";
            this.$emit('refresh')
        },
        scrollToBottom(index){
            setTimeout(function () { 
                let modalBody = document.getElementById('modalGlobal-'+index+'___BV_modal_body_');
                if(modalBody){
                    modalBody.scrollTop = modalBody.scrollHeight
                }
            }, 800)
        },
        handleOpen(msgId,index) {
            this.$nextTick(() => { 
                if (this.document){
                    this.getDocumentMsgList(this.document);
                }
                else{
                    this.getCommandeMsgList(msgId);
                    this.scrollToBottom(index);
                }
            })
        },
        getDocumentMsgList(id){
            var params={
                num_document:id,
            };
            // this.sending =true ;
            axios.get(apiUrl + "/api/admin/v1/messages", {
                params: params
            })
            .then(response => {
                this.globalMsgs=response.data.data;
                this.numCmd=response.data.data.dossier ? response.data.data.dossier.numero : ' ';
                if(response.data.data.document){
                    this.libelleDoc=response.data.data.document.libelle_doc ;
                }
                this.loading=false;
                // this.sending =false ;
            })
            .catch(error => {
                // this.sending =false ;
            });
        },
        getCommandeMsgList(id){
            var params={
                message_id:id,
            };
            axios.get(apiUrl + "/api/common/v1/message/comments", {
                params: params
            })
            .then(response => {
                this.globalMsgs=response.data;
                this.numCmd=response.data.data.dossier ? response.data.data.dossier.numero : ' ';
                if(response.data.data.document){
                    this.libelleDoc=response.data.data.document.libelle_doc ;
                }
                this.loading=false;
            })
            .catch(error => console.log(error));
        },
        reloadComments(id) {
            var params={message_id:id};
            axios.get(apiUrl + "/api/common/v1/message/comments", {
                params: params
            })
            .then(response => {
                this.globalMsgs=response.data;
            })
            .catch(error => console.log(error));
        },
        downloadDocs(file_url, file_name){
            let config = {
                responseType: "arraybuffer",
            }
            axios.get(apiUrl + '/api/common/v1' + file_url, config)
            .then((response) => {
                const blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = file_name;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(error => {
                console.error("error: ",error);
            });
        },
    },
    watch : {
        refreshComments(value){           
            if(value && this.selectedModal == this.msgId){                
                this.reloadComments(this.msgId) ;
                this.$emit('reinitRefresh');
            }            
        }
    }
}
</script>
<style lang="scss">
    .modal-body {
        margin-top: 10px;
    }
    .modal-header{
        border-bottom: none;
    }
    .modal-content {
        max-height: 80vh;
    }

</style>