<template>
  <div class="language-switch">
      <b-nav-item-dropdown
        id="toggle-dropdown"
        text=""
        toggle-class="nav-link-custom"
        right
      >
      <template slot="button-content">
          <flag :iso="currentLocale" class="flag-img"/>
          <i class="feather-chevron-down"></i>
      </template>
      <b-dropdown-item v-for="(lang, index) in languages" :key="index" @click="changeLanguage(lang)">
          <flag :iso="lang" class="flag-img"/>
          <span class="name-lg"> {{lang}} </span>
      </b-dropdown-item>
      </b-nav-item-dropdown>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        languages: Object.keys(this.$i18n.messages),
      };
    },
    computed: {
      currentLocale() {
        return this.$i18n.locale;
      },
    },
    methods: {
      changeLanguage(locale) {
        this.$i18n.locale = locale;
        this.$router.push({ name: this.$route.name, params: { lang: locale } });
        localStorage.setItem('lang', locale);
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>