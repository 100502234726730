class Profile {
    constructor () {
    }
    /**
     *
     * @param role
     * @returns {boolean}
     */
     hasRole (role) {
        let _role = JSON.parse(localStorage.getItem("function")).id;
        return _role === role ? true : false;
    }

    /**
     *
     * @param roles
     * @returns {boolean|*}
     */
    hasRoles (roles) {
        let _role = JSON.parse(localStorage.getItem("function")).id;
         if ( Array.isArray(roles) ) {
            return roles.includes(_role)
        }
        else {
            return this.hasRole(roles);
        }
    }
    /**
     *
     * @param profiles
     * @returns {boolean|*}
     */
      hasProfiles (profiles) {
        let _role = JSON.parse(localStorage.getItem("profil")).id;
         if ( Array.isArray(profiles) ) {
            return profiles.includes(_role)
        }
        else {
            return this.hasRole(profiles);
        }
    }
}

export default Profile;
