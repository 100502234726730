<template>
<div>
      <!--modal Contact-->
      <b-modal id="modalacontact"  ref="suppContactModal" :title="$t('wording.signeAbus')"  no-close-on-backdrop>
        <template #modal-header="{ close }">
             <div class="m-auto">
              <h5 class="modal-title">{{$t('wording.signeAbus')}}</h5>
              <p v-if="currentFolder!=null"> Dossier : {{currentFolder}}</p>

              </div>
              <button type="button" aria-label="Close" class="close"  @click="hideModal('suppContactModal')"><i class="feather-x"></i></button>
          </template>
        <b-form class="av-tooltip tooltip-label-right contact-form">
          <b-row>
               <b-colxx md="12" xl="12">
              <b-form-group>
                <label>{{$t('wording.title')}}<span class="text-danger">*</span></label>
                  <b-input   
                     type="text" 
                     v-model="signalerForm.title"
                     :class="{
                        'is-invalid': $v.signalerForm.title.$error,
                      }" />
              </b-form-group>
            </b-colxx>
            <b-colxx md="12" xl="12">
              <b-form-group>
                  <label>{{$t('wording.message')}}<span class="text-danger">*</span></label>
                <textarea
                  class="form-control"
                  v-model.trim="$v.signalerForm.body.$model"
                  rows="6"
                  :class="{
                        'is-invalid': $v.signalerForm.body.$error,
                      }"
                  ></textarea>
              </b-form-group>
            </b-colxx>
                <!-- FILES -->
              <b-colxx md="12" xl="12">
                  <div class="form-group">
                    <label for="">{{ $t("table.header.pieceJointe")}}</label>
                    <div class="full--width _file--container d-flex">
                      <b-form-file
                        v-model="signalerForm.file"
                        ref="file-input"
                        placeholder="Déposer un fichier ici pour télécharger"
                        class="mb-2"
                        
                      >
                        <template slot="file-name" slot-scope="{ names }">
                          <b-badge variant="default">{{ names[0] }}</b-badge>
                          <b-badge
                            v-if="names.length > 1"
                            variant="dark"
                            class="ml-1"
                          >
                            + {{ names.length - 1 }}
                          </b-badge>
                        </template>
                      </b-form-file>
                      <b-button
                        @click="signalerForm.file = null"
                        v-if="signalerForm.file"
                        class="default"
                      >
                        <i class="feather-x"></i>
                      </b-button>
                    </div>
                  </div>
              </b-colxx>
                    <!-- checkbox  SEND EMAIL-->
                      <b-colxx md="12" xl="12">
                  <div class="col-12 form-group form-group-checkbox">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="signalerForm.withSupportContact"
                      name="checkbox-1"
                    >
                    Souhaitez vous être recontacter par le support ?
                    </b-form-checkbox>
                  </div>
                      </b-colxx>
          </b-row>
        </b-form>
        <template slot="modal-footer">
          <div class="d-flex justify-content-between" style="width: 100%;">
            <button class="cancel-btn" @click="hideModal('suppContactModal')">
              {{ $t('wording.annuler') }}
            </button>
            <button class="save-btn" @click="submitContact()">
              {{ $t('wording.confirmer') }}
            </button>
          </div>
        </template>
      </b-modal>
</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { apiUrl } from '../../constants/config'
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
  name: 'contactModal',
  components: {},
  props : {
    showContactModal: {
      type:Boolean,
      default: false,
    },
    isFormDetailsCMD:{
      type:Boolean,
      default: false
     },
    selectedFolder:{
      type: Object, 
      default: null
    },  
  },
  data() {
    return {
      currentFolder: null,
      signalerForm: {
        title: '',
        body: '',
        file :null,
        withSupportContact:false
      },
      message: '',
      isClient: null,
    }
  },
  mixins: [validationMixin],
  validations: {
    signalerForm: {
      title: {
        required,
      },
      body: {
        required,
      },
    },
  },
  watch:{
    showContactModal:function(value){
      if(value==true){
        this.currentFolder=this.isFormDetailsCMD == true ?this.selectedFolder.numero :null;
        this.$bvModal.show('modalacontact')
      }
    }
  },
  methods: {
    ...mapActions(['signOut']),
    submitContact() {
      this.$v.signalerForm.$touch()
      if (this.$v.signalerForm.$invalid) {
        this.submitStatus = 'ERROR'
        this.$notify('error', this.$t('wording.errorForms'), '', {
          duration: 5000,
          permanent: false,
        })
      } else {
        let body = new FormData()
        body.append('title', this.signalerForm.title)
        body.append('document', this.signalerForm.file)
        body.append('withSupportContact', this.signalerForm.withSupportContact)
        body.append('body', this.signalerForm.body)
        if(this.isFormDetailsCMD ==true){
            body.append('workflow', localStorage.getItem("WF-Profil"))
        }
        axios
          .post(`${apiUrl}/api/common/v1/contact`, body, {})
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.$notify('success', this.$t('wording.msgSend'), '', {
                duration: 5000,
                permanent: false,
              })
              // this.hideModal('modalacontact');
              this.signalerForm = { title: '', body: '', file: null }
              this.$v.$reset()
              this.$bvModal.hide('modalacontact')
            }
           // this.$emit('changesSaved')
          })
          .catch((error) => console.log(error))
      }
    },
    hideModal(refname) {
      this.$v.$reset()
      this.$bvModal.hide('modalacontact')
      this.$emit('hideModal')

    },
     
  },
}
</script>