<template>
    <div>
        <b-modal :id="id" centered hide-footer hide-header class="modal-confirm-valid-date" modal-class="rx-dll-modal rx-dll-modal-p-0" size="lg" no-close-on-backdrop>
            <div class="add-message">
                <div class="text-right">
                    <button class="btn" @click="hideModal"><i class="ri-close-line text-danger"></i></button>
                </div>

                <h3 class="text-center" v-if="!isEdit">{{ createAction }}</h3>
                <div v-if="!is_loading" class="row">
                    <div class="col-12">
                        <b-form @submit.prevent="addMessage()">
                            <b-card class="mb-2">
                                <div class="row">
                                    <div class="col-lg-8 col-12">
                                        <div class="form-group">
                                            <label for="">{{ titleAction }} </label>
                                            <input v-model="msgForm.title" type="text" :class="{'is-invalid': submitted && $v.msgForm.title.$error,}" :formatter="formatter" class="form-control" :disabled="isEdit"/>
                                            <div v-if="submitted && !$v.msgForm.title.required" class="invalid-feedback">
                                                {{ $t("validation.inputReq") }}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="">{{$t("wording.message")}}</label>
                                            <b-form-textarea id="textarea-large" v-model="msgForm.message" lazy-formatter :formatter="formatter" :class="{'is-invalid': submitted && $v.msgForm.message.$error,}" rows="6" :disabled="isEdit"></b-form-textarea>
                                            <div v-if="submitted && !$v.msgForm.message.required" class="invalid-feedback">
                                                {{ $t("validation.inputReq") }}
                                            </div>
                                        </div>
                                        <!--priorité -->
                                        <div v-if="displayReminders" class="form-group-copy">
                                            <label for="">{{$t("wording.priorite")}}</label>
                                            <treeselect :disabled="isEdit"  v-model="msgForm.priority" :class="{'is-invalid':submitted && $v.msgForm.priority.$error,}" :multiple="false" placeholder="" :options="labels" :normalizer="normalizer" :clearable="false" />
                                            <div v-if="submitted && !$v.msgForm.priority.required" class="invalid-feedback">
                                                {{ $t("validation.inputReq") }}
                                            </div>
                                        </div>
                                        <!-- FILES -->
                                        <div class="form-group files_input_container">
                                            <label for="">{{ $t("wording.pieceJointe")}}</label>
                                            <div class="full--width _file--container d-flex">
                                            <b-form-file v-if="typeof msgForm.file=='string'" v-model="msgForm.file" ref="file-input" :placeholder="msgForm.file" accept="application/pdf">
                                                {{ msgForm.file }}
                                            </b-form-file>
                                            <b-form-file v-else v-model="msgForm.file" ref="file-input" placeholder="Déposer un fichier" class="mb-2" >
                                                <template slot="file-name" slot-scope="{ names }">
                                                    <b-badge variant="default">{{ names[0] }}</b-badge>
                                                    <b-badge v-if="names.length > 1" variant="dark"  class="ml-1" >
                                                        + {{ names.length - 1 }}
                                                    </b-badge>
                                                </template>
                                            </b-form-file>
                                            <b-button v-if="msgForm.file" @click="msgForm.file = null" class="default" >
                                                <i class="feather-x decline_icon"></i>
                                            </b-button>
                                            </div>
                                        </div>
                                    </div>
                                    <!--DESTINATAIRES-->
                                    <div class="col-lg-4 col-12">
                                        <!--COMMANDES-->
                                        <div  class="form-group-copy" >
                                            <label for="">{{$t("wording.commande")}}</label>
                                            <treeselect
                                            v-if="planningPage"
                                            :multiple="false"
                                            :noResultsText="$t('placeholder.noResultsText')"
                                            :options="foldersOptions"
                                            :always-open="false"
                                            :placeholder="$t('placeholder.searchFolder')"
                                            :searchPromptText="$t('placeholder.searchFolderPromp')"
                                            v-model="msgForm.commandes"
                                            :loadingText="$t('placeholder.loadingFolderText')"
                                            :async="true"
                                            :load-options="loadOptions"
                                            :autoLoadRootOptions="false"
                                            :class="{'is-invalid':submitted && $v.msgForm.commandes.$error,}"
                                            />
                                            <input v-else-if="isFromCmdDetails && !isEdit" v-model="msgForm.commande_nbr" type="text"  class="form-control" :disabled="isFromCmdDetails"/>
                                            <treeselect
                                            v-else
                                            v-model="msgForm.commandes"
                                            :multiple="false"
                                            :options="foldersList"
                                            :normalizer="normalizerFolders"
                                            placeholder=""
                                            :disabled="isEdit"
                                            :clearable="false"
                                            :class="{'is-invalid':submitted && $v.msgForm.commandes.$error,}"
                                            />

                                            <div v-if="submitted && !$v.msgForm.commandes.required" class="invalid-feedback">
                                                {{ $t("validation.inputReq") }}
                                            </div>
                                        </div>

                                        <!--DATE DEBUT  PUBLICATION -->
                                        <div class="form-group" :class="submitted && $v.msgForm.start_publication_date.$error ? 'invalid-form-group': ''">
                                            <label for="">{{$t("wording.dateCreation")}}</label>
                                            <div class="input-calendar d-flex w-100 flex-column">
                                                <b-form-datepicker :disabled="isEdit" @input="updateEndDate" :class="submitted && $v.msgForm.start_publication_date.$error ? 'is-invalid mx-input': ''" v-model="msgForm.start_publication_date" :min="disabledBeforeStart" locale="fr" :date-format-options="{day: 'numeric' , month: 'numeric', year: 'numeric'}" :placeholder="isEdit ? formatDate(msgForm.start_publication_date) : ''"></b-form-datepicker>
                                                <div v-if="submitted && !$v.msgForm.start_publication_date.required" class="invalid-feedback">
                                                    {{ $t("validation.inputReq") }}
                                                </div>
                                            </div>
                                        </div>
                                        <!--DATE FIN PUBLICATION -->
                                        <div class="form-group" :class="submitted && $v.msgForm.end_publication_date.$error ? 'invalid-form-group': ''">
                                            <label for="">{{$t("wording.dateEchange")}}</label>
                                            <div class="input-calendar d-flex w-100 flex-column">
                                                <b-form-datepicker :disabled = "!msgForm.start_publication_date || isEdit" v-model="msgForm.end_publication_date" :min="disabledBeforeEnd"  locale="fr" :date-format-options="{day: 'numeric' , month: 'numeric', year: 'numeric'}" :placeholder="isEdit ? formatDate(msgForm.end_publication_date) : ''"></b-form-datepicker>
                                                <div v-if="submitted && !$v.msgForm.end_publication_date.required" class="invalid-feedback">
                                                    {{ $t("validation.inputReq") }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--btns-->
                                <div class="separation e-msg"></div>
                                <div class="row">
                                    <div class="col-md-12 mx-auto d-sm-flex d-block  pt-30 pour-action" :class="isEdit ? 'justify-content-end' : 'justify-content-center'">
                                        <button v-if="!isEdit" type="button" class="cancel-btn" @click="hideModal">
                                            {{ $t('wording.retour') }}
                                        </button>
                                        <button v-if="!isEdit" type="submit" class="save-btn">
                                            {{ $t('wording.enrgModif') }}
                                        </button>
                                        <div v-else class="d-flex form-group-copy align-items-center">
                                            <label class="mr-2 mb-0">{{ $t("infos.fait") }}</label>

                                            <b-form-checkbox  v-model="isDone" name="check-button" switch @change="UpdateIsDone($event)">
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </b-card>
                        </b-form>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import DatePicker from 'vue2-datepicker'
    import moment from 'moment';
    import {
        validationMixin
    } from 'vuelidate'

    import {
        required,
        maxLength
    } from 'vuelidate/lib/validators'
    import VueDropzone from 'vue2-dropzone'
    import Treeselect from '@riophae/vue-treeselect'
    import { mapActions, mapGetters  } from 'vuex'
    // import the styles
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        apiUrl
    } from '../../constants/config'
    import axios from 'axios'
    import { ASYNC_SEARCH } from "@riophae/vue-treeselect";
    export default {
        name: "addMessageModal",
        props: ["showeDisscussionModal","isEdit", "SelectedItem", 'labels',"id","isFromCmdDetails","folderDetails","modalType"],
        components: {
            Treeselect,
            DatePicker
        },
        data() {
            return {
                colabOptionsList:[],
                  refSite: this.$route.params.ref_site,
                is_loading:false,
                docsTab: [],
                selectedUserId: null,
                submitted: false,
                selectedRecipient: '',
                msgForm: {
                    commande_nbr: null,
                    file: null,
                    title: '',
                    commandes: null,
                    message: '',
                    priority: 1,
                    start_publication_date: moment().format('YYYY-MM-DD'),
                    end_publication_date: moment().add(7, 'days').format('YYYY-MM-DD'),
                },
                foldersList: [],
                destinatairesTreeSelectOptions: [],
                isDone:false,
                // allFolders : [],
                normalizer(node) {
                    return {
                        id: node.id,
                        label: node.name,
                    }
                },
                normalizerFolders(node) {
                    return {
                        id: node.id_commande,
                        label: node.numero_commande,
                    }
                },
                normalizerDocs(node) {
                    return {
                        id: node.id,
                        label: node.ref_document.info_aide,
                    }
                },
                foldersOptions: []
            };
        },
        mixins: [validationMixin],
        validations: {
            msgForm: {
                title: {
                    required,
                },

                message: {
                    required,
                },
                commandes: {
                    required,
                },
                end_publication_date: {
                    required
                },
                start_publication_date:{
                    required
                },
                priority:{
                    required
                }
            },
        },
        created() {
          // this.getColabList('collaborateurs');
            if(this.folderDetails?.numero){
                     this.msgForm.commande_nbr = this.folderDetails.numero;
            }
            this.msgForm.priority= 3;
        },
        methods: {
    ...mapActions(['getColabList','getNotifications', 'getActionsPlanByFolder','getCmdsList']),
            formatDate(value){
                return moment(String(value)).format('DD/MM/YYYY')
            },
               buildOptionsUsers(data) {
      return data.map((elem) => {
        return {
          id: elem.id ? elem.id : elem.uuid,
          label: elem.prenom ? elem.prenom : (elem.first_name && elem.last_name ? elem.first_name +' '+elem.last_name : elem.first_name),
        }
      })
    },
            showModal() {
                this.$bvModal.show(this.id);
                if(!this.isEdit){
                    this.foldersList = [...this.allCalendarFolder]
                }
                if(this.folderDetails?.numero){
                this.msgForm.commandes = this.folderDetails.id;
                this.msgForm.commande_nbr = this.folderDetails.numero;
              }
            },
            hideModal() {
                this.reinitData()
                this.$emit('closeModal');
                this.$bvModal.hide(this.id)
            },
            addMessage() {
                this.submitted = true
                this.$v.msgForm.$touch()
                if (!this.$v.msgForm.$invalid) {

                    const msg = new FormData();
                    msg.append( "file" , this.msgForm.file);
                    msg.append( "title" , this.msgForm.title);
                    msg.append( "content" , this.msgForm.message);
                    msg.append( "start_publication_at" , this.msgForm.start_publication_date);
                    msg.append( "end_publication_at" , this.msgForm.end_publication_date);
                    msg.append( "label_id" , this.displayReminders ? this.msgForm.priority : 1);
                    msg.append( "num_dossier" , this.msgForm.commandes);
                    msg.append( "users_uuids[0]" , this.currentUser.id);
                    msg.append( "is_published" , false);
                    msg.append( "is_checkable" , 1);
                    this.loadingSave = true
                    axios
                        .post(apiUrl + '/api/admin/v1/messages', msg)
                        .then((response) => {
                            if (response.status === 200 || response.status === 201) {
                                this.$emit('AppendMessage',response.data.data)
                                this.$notify('success', this.$t('wording.msgAdded'), '', {
                                    duration: 5000,
                                    permanent: false,
                                })
                                !this.planningPage && this.getNotifications(this.$route.params.num_cmd ?? null);
                                this.folderDetails && this.getActionsPlanByFolder(this.folderDetails.numero);
                                this.hideModal();
                            }
                        })
                        .catch((error) => {
                            console.error('error : ', error)
                            this.scrollToTop()
                            this.$notify('error', this.$t('wording.errorForms'), '', {
                                duration: 5000,
                                permanent: false,
                            })
                        })
                }
            },
            scrollToTop() {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                })
            },
            formatter(value) {
                return value.toLowerCase()
            },
            reinitData(){
                this.destinatairesTreeSelectOptions = [];
                this.foldersList = [],
                this.msgForm = {
                    file:null,
                    commande_nbr: null,
                    title: '',
                    commandes: null,
                    message: '',
                    priority: 1,
                    start_publication_date: moment().format('YYYY-MM-DD'),
                    end_publication_date: moment().add(7, 'days').format('YYYY-MM-DD'),
                }
                this.$v.$reset()
            },
            UpdateIsDone(event){
                this.$emit('refresh', event, this.SelectedItem)
            },
            updateEndDate(){
                if(!this.isEdit){
                    let result = moment(this.msgForm.start_publication_date).add(7, 'days').format('YYYY-MM-DD');
                    this.msgForm.end_publication_date = result;
                }
            },
            loadOptions({ action, searchQuery, callback }) {
                if (action === ASYNC_SEARCH) {
                const config={
                    search : searchQuery
                }
                this.getCmdsList(config)
                    .then((result) => {
                        const options = this.buildFoldersOptions(result.data.data);
                        this.foldersOptions = options;
                        callback(null, options );
                    })
                    .catch((error) => {
                        console.error("Error : ", error);
                    });
                }
            },
            buildFoldersOptions(data) {
                return data.map((elem) => {
                    return {
                    id: elem.id,
                    label: elem.numero,
                    };
                });
            },
        },
        watch: {
            users:function(value){
                  this.colabOptionsList = this.buildOptionsUsers(value)
            },
           isFromCmdDetails : function (value) {
                if (value == true) {
                    if(!this.isEdit){
                        this.msgForm.commandes = this.folderDetails.id;
                        this.msgForm.commande_nbr = this.folderDetails.numero;

                    }

                }
           },
            showeDisscussionModal: function (value) {
                if (value == true) {
                    this.showModal()
                }

            },
            'msgForm.commandes': function (value) {
                if(value && !this.isEdit){
                    let acteurs= this.foldersList.filter(item => item.id_commande==value)
                    if(acteurs&&acteurs.length) {
                           let user = {
                        id: acteurs[0].vendeur.id,
                        label: acteurs[0].vendeur.first_name+' '+acteurs[0].vendeur.last_name
                    }
                    let client = {
                        id: acteurs[0].client.user.id,
                        label: acteurs[0].client.nom_prenom ? acteurs[0].client.nom_prenom : acteurs[0].client.prenom+' '+acteurs[0].client.nom
                    }
                    this.destinatairesTreeSelectOptions = [client, user]
                    }


                }
                else if (!this.isEdit && !this.isFromCmdDetails){
                    this.destinatairesTreeSelectOptions = []
                }
            },
            'msgForm.start_publication_date': function(value)  {
                if(value){
                    if(this.msgForm.end_publication_date){
                        if(value>this.msgForm.end_publication_date){
                            this.msgForm.end_publication_date= null
                        }
                    }
                }
            },
            SelectedItem: function(value){
                if(value && this.isEdit){
                    this.destinatairesTreeSelectOptions = [{
                        id: value.user_id,
                        label: value.last_name ? value.last_name+' '+value.first_name : value.first_name
                    }],
                    this.foldersList = [
                        {
                            id_commande:value.folder.id,
                            numero_commande: value.folder.numero
                        }
                    ]
                    this.msgForm.commandes=value.folder.id;
                    this.msgForm.end_publication_date= value.answers[0].end_publication_at
                    this.msgForm.start_publication_date= value.answers[0].start_publication_at
                    this.msgForm.message = value.answers[0].content
                    this.msgForm.priority  = value.label.id
                    this.msgForm.title = value.answers[0].title,
                    this.isDone = value.is_read;
                }
            },
        },
        computed:{
            ...mapGetters(['currentUser','allCalendarFolder','users']) ,
            disabledBeforeStart(){
                if(!this.isEdit){
                    return moment().format('YYYY-MM-DD')
                }
            },
            disabledBeforeEnd(){
                if(!this.isEdit){
                    if(this.msgForm.start_publication_date){
                        let result = moment(this.msgForm.start_publication_date).format('YYYY-MM-DD');
                        return result
                    }
                }
            },
            planningPage(){
                return this.$route.name == 'planning';
            },
            displayReminders(){
                return this.modalType== 'reminders';
            },
            createAction(){
                return this.displayReminders ? this.$t('title.nvAction') : this.$t('title.nvAlerte');
            },
            titleAction(){
                return this.displayReminders ? this.$t('title.titleAction') : this.$t('title.titleAlert');
            }
        }
    }
    </script>
