<template>
  <div class="breadcrumb-section col-12" v-if="items.length">
    <b-breadcrumb :key="breadcrumbKey" :items="items"></b-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      items: [],
      breadcrumbKey: 0,
    };
  },
  watch: {
    $routes() {
      this.items = this.$route.meta.breadcrumb;
    },
    "$route.meta.breadcrumb"(value) {
      this.items = this.$route.meta.breadcrumb;
      this.breadcrumbKey++;
    },
    "$route.params.num_cmd"(value) {
      if (this.$route.name == "showNewCommandesDetails") {
       // this.items[2] = value;
      }
    },
  },
  mounted() {
    this.items = this.$route.meta.breadcrumb.length
      ? this.$route.meta.breadcrumb
      : [];
  },
  methods: {
    formatItems(tab) {
      return tab.map((item) => {
        return {
          text: item.name,
          href: item.link,
        };
      });
    },
  },
};
</script>
