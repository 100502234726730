<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix d-flex">
        <div class="col-6">
        <span class="d-block text-center text-sm-left d-sm-inline-block">{{$t('wording.copyright')}} </span>
        <!-- <a href="https://www.e2lys.fr/" target="_blank"> {{$t('wording.e2lys')}} </a>  -->
        </div><div class="col-6 d-flex justify-content-end">
         <span class="d-block text-center text-sm-right d-sm-inline-block version-block">{{appVer}}</span>
         </div>
      </div>
    </footer>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'app-footer',
   data() {
    return {
      version:"",
    };
  },
    computed: {
    ...mapGetters(["successSaveConfigSite"]),
      appVer() {
        return process.env.VUE_APP_VER
      },
  },
  watch:{
    successSaveConfigSite(value){
      if(value ==true){
        this.version = JSON.parse(localStorage.getItem("settings")).version
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.version-block{
  color: #c2c5c4;
  font-weight: 500;
}

</style>
