<template>
  <div id="app" translate="no">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  },
        watch: {
            $route: {
                immediate: true,
                handler () {
                 const content_layout =document.getElementsByClassName('content-wrapper');
                  if(this.$route.name=="dashboard"){
                     if(content_layout&&content_layout[0]){content_layout[0].classList.add("no-padding");}
                  }else{
                      if(content_layout&&content_layout[0]){content_layout[0].classList.remove("no-padding");}
                }
            }
            }
        },
  
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "./assets/scss/style";
@import "src/assets/css/feather";
@import "src/assets/css/ionicons";

</style>
<style lang="css">

</style>
