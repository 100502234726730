<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <div
        class="sidebar-brand-wrapper align-items-center justify-content-center fixed-top text-center"
      >
        <router-link
          tag="div"
          class="sidebar-brand brand-logo"
          to="/"
          v-bind:style="{ backgroundImage: 'url(' +  logo + ')' }"
        >
        </router-link>
      </div>
      <div class="sidebar-nav">
        <!-- menu Admin/chef de vente -->
        <ul v-if="[1, 2, 5, 3,8, 9, 10, 11].includes(function_id)" class="nav nav-settings">
          <!-- DASHBOARD SECREATAIRE -->
          <li class="nav-item menu-items" v-can:enable="[1, 2, 5, 3,8]">
            <span
              class="nav-link"
              v-b-toggle="[disableToggle ? '' : 'ui-basic-dashboardSecretaire']"
              :class="{ active: subIsActive('/ui-basic-dashboardSecretaire') }"
            >
              <span class="menu-icon">
                <i class="feather-grid"></i>
              </span>
              <span class="menu-title"> {{ $t("sidebar.dashboard") }}</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse
              accordion="sidebar-accordion"
              id="ui-basic-dashboardSecretaire"
            >
              <ul class="nav flex-column sub-menu">
                <li
                  v-for="(item, index) in Sites"
                  :key="index"
                  class="nav-item"
                >
                  <router-link
                    :disabled="item.ref_site == null"
                    class="nav-link"
                    :to="
                      item.ref_site == null
                        ? `/${$i18n.locale}/error-404`
                        : {
                            name: 'dashboardSecretaire',
                            params: { ref_site: item.ref_site, lang: $i18n.locale },
                          }
                    "
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- clients -->
          <li class="nav-item menu-items">
            <router-link class="nav-link" :to="{ name: 'listClient' }">
              <span class="menu-icon">
                <i class="feather-users"></i>
              </span>

              <span class="menu-title">{{ $t("sidebar.client") }}</span>
            </router-link>
          </li>
          <!-- Entrée VO -->
          <li
              class="nav-item menu-items"
              v-on:click="collapseAll"
              v-can:enable="[5,9,10,11]"
          >
            <router-link class="nav-link" :to="{ name: 'entreeVO' }">
              <span class="menu-icon">
                <i class="feather-file"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.entreeVO") }}</span>
            </router-link>
          </li>
          <!-- New commandes -->
          <li class="nav-item menu-items" v-can:enable="[1,2,3,5, 8]">
            <span
                class="nav-link"
                v-b-toggle="[disableToggle ? '' : 'ui-basic']"
                :class="{ active: subIsActive('/basic-ui') }"
            >
              <span class="menu-icon">
                <i class="ri-car-line"></i>
              </span>
              <span class="menu-title title-commande">
                {{ $t("sidebar.newCommande") }}
              </span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item factureUnmarked" v-can:enable="[1,2,3,5, 8]">
                  <span class="menu-title custum-item">{{ $t("sidebar.BonsCommande") }}</span>
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item"  v-for="(item, index) in Sites"
                        :key="index"
                    >
                      <router-link
                          class="nav-link"
                          :to="{name: 'newCommandesBySite',  params: { ref_site: item.ref_site }}"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- commandes VO -->
          <!-- Facture contre marqu -->
          <li class="nav-item menu-items" v-can:enable="[2,3, 8]">
            <span
                class="nav-link"
                v-b-toggle="[disableToggle ? '' : 'ui-basic-factureUnmarked']"
                :class="{ active: subIsActive('/basic-ui-factureUnmarked') }"
            >
              <span class="menu-icon">
               <i class="ri-money-euro-box-line"></i>
              </span>
              <span class="menu-title title-commande">
                {{ $t("sidebar.factureUnmarked") }}
              </span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic-factureUnmarked">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item factureUnmarked" v-can:enable="[2,3, 8]">
                  <span class="menu-title custum-item">{{ $t("sidebar.factureUnmarked") }}</span>
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item"  v-for="(item, index) in Sites"
                        :key="index"
                    >
                      <router-link
                          class="nav-link"
                          :to="{name: 'factureUnmarked',  params: { ref_site: item.ref_site }}"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-collapse>
          </li>
          <li class="nav-item menu-items" v-can:enable="[5,9,10]">
            <span
                class="nav-link"
                v-b-toggle="[disableToggle ? '' : 'ui-basic']"
                :class="{ active: subIsActive('/basic-ui') }"
            >
              <span class="menu-icon">
                <i class="ri-car-line"></i>
              </span>
              <span class="menu-title title-commande">
                {{ $t("sidebar.commandeVO") }}
              </span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic">
              <ul class="nav flex-column sub-menu">
                <li class="nav-item">
                  <router-link class="nav-link" :to="{ name: 'commandesVO' }">
                    {{ $t("sidebar.BonsCommandeVO") }}
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- Affectation portefeuille -->
          <li v-can:enable="[2,3,5, 8]" class="nav-item menu-items">
            <span
                class="nav-link"
                v-b-toggle="[disableToggle ? '' : 'ui-basic-portfolio']"
                :class="{ active: subIsActive('/ui-basic-portfolio') }"
            >
              <span class="menu-icon">
                <i class="ri-ghost-fill"></i>
              </span>
              <span class="menu-title">
                {{ $t("sidebar.portefeuilleAffectation") }}</span
              >
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic-portfolio">
              <ul class="nav flex-column sub-menu">
                <li
                    v-for="(item, index) in Sites"
                    :key="index"
                    class="nav-item"
                >
                  <router-link
                      :disabled="item.ref_site == null"
                      class="nav-link"
                      :to="
                      item.ref_site == null
                        ? `/${$i18n.locale}/error-404`
                        : {
                            name: 'portfolioAssignment',
                            params: { ref_site: item.ref_site },
                          }
                    "
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- Affectation portefeuille VO -->
          <li v-can:enable="[5, 10, 11]" class="nav-item menu-items">
            <span
                class="nav-link"
                v-b-toggle="[disableToggle ? '' : 'ui-basic-portfolio']"
                :class="{ active: subIsActive('/ui-basic-portfolio') }"
            >
              <span class="menu-icon">
                <i class="ri-calendar-check-line"></i>
              </span>
              <span class="menu-title">
                {{ $t("sidebar.portefeuilleAffectation") }}</span
              >
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic-portfolio">
              <ul class="nav flex-column sub-menu">
                <li
                    v-for="(item, index) in Sites"
                    :key="index"
                    class="nav-item"
                >
                  <router-link
                      :disabled="item.ref_site == null"
                      class="nav-link"
                      :to="
                      item.ref_site == null
                        ? `/${$i18n.locale}/error-404`
                        : {
                            name: 'portfolioAssignmentVO',
                            params: { ref_site: item.ref_site },
                          }
                    "
                  >
                    {{ item.name }}
                  </router-link>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- cmds Preparation -->
          <li
            class="nav-item menu-items"
            v-on:click="collapseAll"
            v-can:enable="[5,3,2, 8]"
          >
            <router-link class="nav-link" :to="{ name: 'documents' }">
              <span class="menu-icon">
                <i class="feather-file"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.documents") }}</span>
            </router-link>
          </li>
          <!-- cmds Preparation VO -->
          <li
              class="nav-item menu-items"
              v-on:click="collapseAll"
              v-can:enable="[5,10,11]"
          >
            <router-link class="nav-link" :to="{ name: 'documentsVO' }">
              <span class="menu-icon">
                <i class="feather-file"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.documentsVO") }}</span>
            </router-link>
          </li>
          <!-- messages -->
          <li
            class="nav-item menu-items"
            v-on:click="collapseAll"
            v-can:enable="[5]"
          >
            <router-link class="nav-link" :to="{ name: 'messages' }">
              <span class="menu-icon">
                <i class="feather-message-square"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.messages") }}</span>
            </router-link>
          </li>
          <!-- Gestion de la livraison -->
          <li
            class="nav-item menu-items"
            v-can:enable="[3,2,5, 8]"
            v-on:click="collapseAll"
          >
            <router-link class="nav-link" :to="{ name: 'gestionLivraison' }">
              <span class="menu-icon">
                <i class="ri-inbox-unarchive-line"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.suiviLiv") }}</span>
            </router-link>
          </li>
          <!-- Gestion de la livraison VO -->
          <li
              class="nav-item menu-items"
              v-can:enable="[5, 10,11]"
              v-on:click="collapseAll"
          >
            <router-link class="nav-link" :to="{ name: 'gestionLivraisonVO' }">
              <span class="menu-icon">
                <i class="ri-inbox-unarchive-line"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.suiviLivVO") }}</span>
            </router-link>
          </li>
          <!-- Plannings -->
          <li v-can:enable="[1,2,5, 8]" class="nav-item menu-items plannings__rc">
            <span
              class="nav-link"
              v-b-toggle="[disableToggle ? '' : 'ui-basic-planning']"
              :class="{ active: subIsActive('/ui-basic-planning') }"
            >
              <span class="menu-icon">
                <i class="ri-calendar-check-line"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.planning") }}</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic-planning">
              <ul class="nav flex-column sub-menu _planified--plannings">
                <li
                  v-for="(item, index) in Sites"
                  :key="index"
                  class="nav-item nav-item_plannings"
                >
                  <router-link
                    v-if="item.plannings.length == 0"
                    class="menu-title custum-item planning-item-link"
                    :disabled="item.ref_site == null"
                    tag="span"
                    :to="
                      item.ref_site == null
                        ? `/${$i18n.locale}/error-404`
                        : { name: 'emptyData' }
                    "
                  >
                    {{ item.name }}
                  </router-link>
                  <span v-else class="menu-title custum-item">{{
                    item.name
                  }}</span>
                  <ul class="nav flex-column sub-menu __sub-plannings">
                    <li
                      v-for="(planning, indice) in item.plannings"
                      :key="indice"
                      class="nav-item-child"
                    >
                      <router-link
                        class="nav-link nav-plannings-child"
                        :disabled="planning.ref_site == null"
                        :to="
                          planning.ref_site == null
                            ? `/${$i18n.locale}/error-404`
                            : {
                                name: 'planning',
                                params: {
                                  ref_site: planning.ref_site,
                                  planning_id: planning.id,
                                },
                              }
                        "
                      >
                        {{ planning.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- Tâches planifiées -->
          <li class="nav-item menu-items plannings__rc" v-can:enable="[3]">
            <span
              class="nav-link"
              v-b-toggle="[disableToggle ? '' : 'ui-basic-planning']"
              :class="{ active: subIsActive('/ui-basic-planning') }"
            >
              <span class="menu-icon">
                <i class="ri-calendar-check-line"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.planning") }}</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse
              accordion="sidebar-accordion"
              class="plannings_list"
              id="ui-basic-planning"
            >
              <ul class="nav flex-column sub-menu _planified--plannings">
                <li
                  v-for="(item, index) in Sites"
                  :key="index"
                  class="nav-item nav-item_plannings"
                >
                  <router-link
                    v-if="item.plannings.length == 0"
                    class="menu-title custum-item planning-item-link"
                    :disabled="item.ref_site == null"
                    tag="span"
                    :to="
                      item.ref_site == null
                        ? `/${$i18n.locale}/error-404`
                        : { name: 'emptyData' }
                    "
                  >
                    {{ item.name }}
                  </router-link>
                  <span v-else class="menu-title custum-item">{{
                    item.name
                  }}</span>
                  <ul class="nav flex-column sub-menu __sub-plannings">
                    <li
                      v-for="(planning, indice) in item.plannings"
                      :key="indice"
                      class="nav-item-child"
                    >
                      <router-link
                        class="nav-link nav-plannings-child"
                        :disabled="planning.ref_site == null"
                        :to="
                          planning.ref_site == null
                            ? `/${$i18n.locale}/error-404`
                            : {
                                name: 'planning',
                                params: {
                                  ref_site: planning.ref_site,
                                  planning_id: planning.id,
                                },
                              }
                        "
                      >
                        {{ planning.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-collapse>
          </li>
          <!-- Animation -->

          <li  v-if="! menuToDisable?.animation" class="nav-item menu-items animation__rc" v-can:enable="[2,3,5, 8]">
            <span
                class="nav-link"
                v-b-toggle="['ui-basic-animation']"
            >
              <span class="menu-icon">
                <i class=" mdi mdi-animation"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.animation.title") }}</span>
              <i class="menu-arrow"></i>
            </span>
            <b-collapse
                accordion="sidebar-accordion"
                class="plannings_list"
                id="ui-basic-animation"
            >
                <ul class="nav flex-column sub-menu">
                  <li>
                    <span class="menu-title custum-item">{{ $t("sidebar.animation.fiche") }}</span>
                    <ul class="nav flex-column sub-menu">
                      <li class="nav-item"  v-for="(item, index) in Sites"
                          :key="index"
                      >
                        <router-link
                            class="nav-link"
                            :to="{name: 'animationSellerSheet',  params: { ref_site: item.ref_site }}"
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span class="menu-title custum-item">{{ $t("sidebar.animation.suivi") }}</span>
                    <ul class="nav flex-column sub-menu">

                      <li class="nav-item"  v-for="(item, index) in Sites"
                          :key="index"
                      >
                        <router-link
                            class="nav-link"
                            :to="{name: 'animationFollow',  params: { ref_site: item.ref_site }}"
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>

                </ul>

            </b-collapse>
          </li>

          <li class="nav-item menu-items prametrage" v-can:enable="[3,2,8,5]">
            <span
              class="nav-link"
              v-b-toggle="[disableToggle ? '' : 'ui-basic-parametrage']"
              :class="{ active: subIsActive('/ui-basic-parametrage') }"
            >
              <span class="menu-icon">
                <i class="ri-settings-3-line"></i>
              </span>
              <span class="menu-title">{{ $t("sidebar.settings") }}</span>
            </span>
            <b-collapse accordion="sidebar-accordion" id="ui-basic-parametrage">
              <ul class="nav flex-column sub-menu">

                  <li v-can:enable="[3,5]">
                    <span class="menu-title custum-item">Planning</span>
                    <ul class="nav flex-column sub-menu">
                      <li
                          v-for="(item, index) in Sites"
                          :key="index"
                          class="nav-item"
                      >
                        <router-link
                            :disabled="item.ref_site == null"
                            class="nav-link"
                            :to="
                      item.ref_site == null
                        ? `/${$i18n.locale}/error-404`
                        : {
                            name: 'settings',
                            params: { ref_site: item.ref_site },
                          }
                    "
                        >
                          {{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                <li v-can:enable="[2,5,8]">
                  <span class="menu-title custum-item">Objectif</span>
                  <ul class="nav flex-column sub-menu">

                    <li class="nav-item"  v-for="(item, index) in Sites"
                        :key="index"
                        >
                      <router-link
                          class="nav-link"
                          :to="{name: 'salesObjectives',  params: { ref_site: item.ref_site }}"
                      >
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </b-collapse>
          </li>
        </ul>
      </div>
      <!-- FOOTER -->
      <div class="sidebar-nav-footer">
        <div class="separator-horizontal"></div>
        <a class="footer-link"  @click="$bvModal.show('modalahowuse')">
          {{ $t("sidebar.help") }}
        </a>
        <span class="separator">-</span>
        <a class="footer-link" @click="contactSupportAction()">
          {{ $t("sidebar.contact") }}
        </a>
      </div>

      <!--modal How to us-->
      <b-modal id="modalahowuse" ref="modalahowuse" hide-footer>
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://www.youtube.com/embed/C0DPdy98e4c"
          allowfullscreen
        ></b-embed>
      </b-modal>
        <contactModal
        :isFormDetailsCMD="isFormDetailsCMD"
        :showContactModal="displayModal"
        :selectedFolder="selectedFolder"
        v-on:hideModal="displayModal = false"
    />
    </nav>
  </section>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import contactModal from "../../layout/partials/contact-modal.vue"
import { apiUrl } from "../../constants/config";
import axios from "axios";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "sidebar",
  components: { contactModal },
  props:{

  },
  data() {
    return {
      logo: null,
      sites: [],
      displayModal: false,
      collapses: [{ show: false }, { show: false }, { show: false }],
      disableToggle: false,
      function_id: null,
      ref_site: this.$route.params.ref_site,
      old_ref_site: this.$route.params.ref_site,
      isFormDetailsCMD:false,
      selectedFolder:null,
      reasons: [
        { id: 1, libelle: "Raison 1" },
        { id: 2, libelle: "Raison 2" },
        { id: 3, libelle: "Raison 3" },
      ],
      signalerForm: {
        title: "",
        body: "",
      },
      reason: "",
      message: "",
      isClient: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    signalerForm: {
      title: {
        required,
      },
      body: {
        required,
      },
    },
  },
  created() {
    if (this.ref_site) {
      this.getDashboradSettings(this.ref_site);
    }
    this.SET_SITES_LIST(this.sites);
    this.function_id = this.$store.getters.currentUser.fonction.id;
    this.isClient =
      this.$store.getters.currentUser.fonction.id == 4 ? true : false;
  },

  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  methods: {
    ...mapActions(["signOut", "getDashboradSettings"]),
    ...mapMutations(["SET_SITES_LIST"]),
    submitContact() {
      this.$v.signalerForm.$touch();
      if (this.$v.signalerForm.$invalid) {
        this.submitStatus = "ERROR";
        this.$notify("error", this.$t("wording.errorForms"), "", {
          duration: 5000,
          permanent: false,
        });
      } else {
        axios
          .post(`${apiUrl}/api/common/v1/contact`, this.signalerForm, {})
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.$notify("success", this.$t("wording.msgSend"), "", {
                duration: 5000,
                permanent: false,
              });
              // this.hideModal('modalacontact');
              this.signalerForm = { title: "", body: "" };
              this.$v.$reset();
              this.$bvModal.hide("modalacontact");
            }
          })
          .catch((error) => console.log(error));
      }
    },
    hideModal(refname) {
      this.$refs[refname].hide();
    },
    contactSupportAction(){
       if (["showCommandeDetails", "showCommande"].includes(this.$route.name)) {
        this.isFormDetailsCMD =true;
        this.selectedFolder = this.$store.getters.folder;
      } else {
         this.isFormDetailsCMD =false;
      }
      this.displayModal=true
    },
    collapseAll() {
      var exp_element = document.getElementsByClassName("show");
      if (exp_element.length > 0) {
        var elm_id = exp_element[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    logoutClient() {
      this.signOut();
    },
  },
  watch: {
    $route(value) {
      if (value.params && value.params.ref_site) {
        if (value.params.ref_site != this.old_ref_site) {
          this.getDashboradSettings(value.params.ref_site).then((res) => {});
          this.old_ref_site = value.params.ref_site;
        }
      }
      document.querySelector("#sidebar").classList.toggle("active");
    },
    successSaveConfigSite(value) {
      if (value == true) {
        this.logo = apiUrl + JSON.parse(localStorage.getItem("settings")).url_logo;
      } else {
        this.logo = require('@/assets/images/logo-mini.svg');
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser", "successSaveConfigSite"]),
    menuToDisable(){
      let listMenu = JSON.parse(process.env.VUE_APP_DISABLE_MENU);
       console.log('process.env.VUE_APP_DISABLE_MENU ',typeof listMenu == 'object' ,listMenu)
      if (typeof listMenu == 'object') {
        return  listMenu;
      }

      return {};
    },
    Sites() {
      if (this.currentUser) {
        this.currentUser.groups.map((item) => {
          if (item.typeGroup == "Site") {
            if (
              this.sites.indexOf((site) => site.ref_site == item.ref_site) < 0
            ) {
              this.sites.push({
                ref_site: item.ref_site,
                name: item.name,
                plannings: item.plannings,
              });
            }
          }
        });
      }
      return this.sites;
    },
    clientCmd() {
      return this.currentUser.client
        ? this.$route.params.dossier
          ? this.$route.params.dossier
          : null
        : null;
    },
  },
};
</script>
