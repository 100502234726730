<template>
  <div>
    <div class="fixed-background"></div>
    <main>
      <div class="container">
           <router-view></router-view>
      </div>
    </main>
  </div>
</template>
<script>
export default {
   name: "userlayout",
  mounted() {
    document.body.classList.add("background");
  },
  beforeDestroy() {
    document.body.classList.remove("background");
  }
};
</script>
