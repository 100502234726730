<template>
  <div>
    
      <b-modal :id="id" centered hide-footer hide-header class="modal-confirm-valid-date" modal-class="rx-dll-modal rx-dll-modal-p-0" size="lg" no-close-on-backdrop>
         
          <div class="add-message">
              <div class="text-right">
                  <button class="btn p-0 btn-close-modal" @click="hideModal"><i class="ri-close-line text-danger"></i></button>
              </div>
              <h3 class="text-center">{{ $t("title.addMessage") }}</h3>  
              <div class="row no-gutters">
                  <div class="col-12">
                      <b-form @submit.prevent="addMessage()">
                          <b-card>
                              <div class="row ">
                                  <div class="col-lg-8 col-12">
                                      <div class="form-group">
                                          <label for="">{{ $t("wording.titre")}}</label>
                                          <input v-model="msgForm.title" type="text" :class="{'is-invalid': submitted && $v.msgForm.title.$error, }" :formatter="formatter" class="form-control" :disabled="disableTitle"/>
                                          <div v-if="submitted && !$v.msgForm.title.required" class="invalid-feedback">
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                      </div>
                                      <div class="form-group">
                                          <label for="">{{ $t("wording.message")}}</label>
                                          <b-form-textarea id="textarea-large" v-model="msgForm.message" lazy-formatter :formatter="formatter" :class="{
                                          'is-invalid': submitted && $v.msgForm.message.$error,
                                          }" rows="6"></b-form-textarea>
                                          <div v-if="submitted && !$v.msgForm.message.required" class="invalid-feedback">
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                      </div>                                            
                                      <div class="form-group">
                                          <label for="">{{ $t("wording.auteur")}}</label>
                                          <b-form-input
                                          id="input-formatter"
                                          v-model="msgForm.author"
                                          :formatter="formatter"
                                          disabled
                                          ></b-form-input>
                                      </div>
                                      <!--priorité -->
                                      <div class="form-group-copy">
                                          <label for="">{{ $t("wording.priorite")}}</label>
                                          <treeselect v-model="msgForm.priority" :class="{'is-invalid':submitted && $v.msgForm.priority.$error,}" :multiple="false" placeholder="" :options="labels" :normalizer="normalizer" :clearable="false" />
                                          <div v-if="submitted && !$v.msgForm.priority.required" class="invalid-feedback">
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                      </div> 
                                      <!-- FILES -->
                                      <div class="form-group files_input_container">
                                          <label for="">{{ $t("wording.pieceJointe")}}</label>
                                          <div class="full--width _file--container d-flex">
                                               <b-form-file v-model="msgForm.file" v-if="typeof msgForm.file=='string'" ref="file-input" :placeholder="msgForm.file" accept="application/pdf">
                                                    {{ msgForm.file }}
                                                </b-form-file>
                                          <b-form-file v-else v-model="msgForm.file" ref="file-input" placeholder="Déposer un fichier" class="mb-2" >
                                              <template slot="file-name" slot-scope="{ names }">
                                              <b-badge variant="default">{{ names[0] }}</b-badge>
                                              <b-badge v-if="names.length > 1" variant="dark"  class="ml-1" >
                                                  + {{ names.length - 1 }}
                                              </b-badge>
                                              </template>
                                          </b-form-file>
                                          <b-button  @click="msgForm.file = null" v-if="msgForm.file" class="default" >
                                              <i class="feather-x decline_icon"></i>
                                          </b-button>
                                          </div>
                                      </div>                        
                                  </div>
                                  <!--DESTINATAIRES-->
                              
                                  <div class="col-lg-4 col-12">                               
                                      <div v-if="notification" class="form-group-copy">
                                          <label for="">{{ $t("wording.destinataires")}}</label>
                                          <treeselect
                                          @input="editCollabType"
                                          v-model="msgForm.destinataire_type"                                
                                          :class="{'is-invalid': submitted && $v.msgForm.destinataire_type.$error, }"
                                          :multiple="false"
                                          :options="destinatairesTypesOptions"
                                          placeholder=""
                                          :clearable="false"                                        
                                          />
                                          <div v-if="submitted && !$v.msgForm.destinataire_type.required"  class="invalid-feedback" >
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                      </div>
                      
                                      <div v-if="notification && ![null,'4',undefined].includes(msgForm.destinataire_type)" class="form-group-copy">
                                          <label for="">{{msgForm.destinataire_type == '3' ? 'Collaborateur(s)' : 'Client(s)'}}</label>
                                          <treeselect @input="getCollabFolders" :disabled="vendeur" v-model="msgForm.destinataire" :class="{'is-invalid':submitted &&![null,'4'].includes(msgForm.destinataire_type)&& $v.msgForm.destinataire.$error,}" :multiple="msgForm.destinataire_type == '3'" :options="destinatairesTreeSelectOptions" placeholder="" :clearable="false"  />
                                          <div v-if="![null,'4'].includes(msgForm.destinataire_type)&&submitted && !$v.msgForm.destinataire.required" class="invalid-feedback">
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                      </div>  
                              
                                    <div class="d-flex align-items-end" v-if="msgForm.destinataire_type=='4'" >    
                                        <div  class="form-group-copy __mail-input" :style="wrongEmail?'margin-bottom:20px':!wrongEmail&&msgForm.mails.length>0?'margin-bottom: 11px':''" >
                                            <label class="control-label requiredField" for="email">
                                              {{ $t("wording.emails") }}
                                            </label>
                                            <b-form-input class="form-control __mail"   :class="{'is-invalid':submitted && $v.msgForm.email.$error,}"  :title="msgForm.email" placeholder="Saisir une adresse email" @keyup="isEmailValid" v-model="msgForm.email" type="email"></b-form-input>
                                             <div v-if="!wrongEmail &&submitted && !$v.msgForm.email.required" class="invalid-feedback _alet-warning">
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                           <span v-show="wrongEmail" class="_alet-warning">{{$t("Parametrages.alerts.invalidEmail")}}</span>
                                        </div>  
                                        <div  :class="(wrongEmail|| msgForm.email=='')?'disabled':''" class="__add-email-icon form-group-copy" @click="addMail(msgForm.email)" :style="wrongEmail?'margin-bottom:20px':!wrongEmail&&msgForm.mails.length>0?'margin-bottom: 11px':''">
                                        <i class="ri-add-line __addEmail-icon"></i> 
                                        </div>    
                                        </div>
                                        <div class=" _mails-containers form-group-copy" v-if="msgForm.mails.length">    
                                         <div class="d-flex align-items-center" :title="mail" v-for="(mail,index) in msgForm.mails" :key="index" ><b-badge variant="secondary" style="margin:1px;border-radius;">{{mail}} </b-badge><i class="ri-close-line" @click="removeMail(mail)"></i></div>
                                        </div>
                                      <!--COMMANDES-->
                                      <div  v-if="foldersList.length" class="form-group-copy" >
                                          <label for="">{{ $t("wording.commande")}}</label>
                                          <treeselect
                                          v-model="msgForm.commandes"
                                          :multiple="false"
                                          :options="foldersList"
                                          :normalizer="normalizerFolders"
                                          placeholder=""
                                          :clearable="false"  
                                          :disabled ="selectedItem!=null"
                                          @input="getFolderDocs"                                                                               
                                          />                               
                                      </div> 
                                      <div  v-if="!notification" class="form-group-copy">
                                          <label for="">{{ $t("wording.destinataires")}}</label>
                                          <treeselect :disabled="vendeur" v-model="msgForm.destinataire" :class="{'is-invalid':submitted && $v.msgForm.destinataire.$error,}" :multiple="false" :options="destinatairesTreeSelectOptions" placeholder="" :clearable="false"  />
                                          <div v-if="submitted && !$v.msgForm.destinataire.required" class="invalid-feedback">
                                            {{ $t("validation.inputReq") }}
                                          </div>
                                      </div>  
                                      <!--DOCUMENTS-->
                                      <div class="form-group-copy"  v-if="msgForm.commandes && msgForm.commandes != null && documentsList.length" >
                                          <label for="">{{ $t("wording.docs")}}</label>
                                          <treeselect :disabled="document!=null" v-model="msgForm.documents" :multiple="false" :normalizer="normalizerDocs"  @input="setCurrentTitleDiscussion(msgForm.commandes,msgForm.documents,
                                          )" :options="documentsList" placeholder="" :clearable="false" />
                                      </div>  
                                      <!--DATE DEBUT  PUBLICATION -->
                                      <div class="form-group" :class="submitted && $v.msgForm.start_publication_date.$error ? 'invalid-form-group': ''">
                                          <label for="">{{ $t("wording.dateCreation")}}</label>
                                          <div class="input-calendar d-flex w-100 flex-column">
                                              <b-form-datepicker @input="updateEndDate" v-model="msgForm.start_publication_date" :min="disabledBeforeStart" locale="fr" :date-format-options="{day: 'numeric' , month: 'numeric', year: 'numeric'}"></b-form-datepicker>
                                              <div v-if="submitted && !$v.msgForm.start_publication_date.required" class="invalid-feedback">
                                                {{ $t("validation.inputReq") }}
                                              </div>
                                          </div>
                                      </div>
                                      <!--DATE FIN PUBLICATION -->
                                      <div class="form-group" :class="submitted && $v.msgForm.end_publication_date.$error ? 'invalid-form-group': ''">
                                          <label for="">{{ $t("wording.dateEchange")}}</label>
                                          <div class="input-calendar d-flex w-100 flex-column">
                                              <b-form-datepicker :disabled = "!msgForm.start_publication_date" v-model="msgForm.end_publication_date" :min="disabledBeforeEnd"  locale="fr" :date-format-options="{day: 'numeric' , month: 'numeric', year: 'numeric'}"></b-form-datepicker>
  
                                              <div v-if="submitted && !$v.msgForm.end_publication_date.required" class="invalid-feedback">
                                                {{ $t("validation.inputReq") }}
                                              </div>
                                          </div>
                                      </div>                                     
                                      <!-- checkbox  SEND EMAIL-->
                                      <!-- <div class="col-12 form-group form-group-checkbox"> 
                                          <b-form-checkbox id="checkbox-1" v-model="msgForm.email_copy" name="checkbox-1" >
                                          Copie mail
                                          </b-form-checkbox> -->
                                          <!-- <p v-if="msgForm.email_copy">
                                          Les utilisateurs avec ou sans Application mobile seront
                                          notifiés par e-mail, les utilisateurs de l'application
                                          sont notifiés aussi par défaut.
                                          </p>
                                          <p v-else>
                                          Les utilisateurs avec Application mobile seront notifiés,
                                          les utilisateurs qui ne disposent pas l'application ne
                                          seront pas notifiés par mail.
                                          </p> -->
                                      <!-- </div>                                -->
                                  </div>
                              </div>
                              <!--btns-->
                              <div class="separation e-msg"></div>
                              <div class="row">
                                  <div class="col-md-12 mx-auto d-sm-flex d-block justify-content-center pt-30">
                                      <button type="button" class="cancel-btn" @click="hideModal">
                                          {{ $t('wording.retour') }}
                                      </button>
                                      <button type="submit" class="save-btn">
                                          {{ $t('wording.enrgModif') }} 
                                          <b-spinner
                                                v-if="create_message_loader"
                                                class="load-files"
                                                label="Loading..."
                                            ></b-spinner>
                                      </button>
                                  </div>
                              </div>
                          </b-card>
                      </b-form>
                  </div>
              </div>
          </div>
      </b-modal>
  </div>
</template>
  
  <script>
  import DatePicker from 'vue2-datepicker'
  import {
      validationMixin
  } from 'vuelidate'
  
  import {
      required,
      requiredIf,
      maxLength,
      email
  } from 'vuelidate/lib/validators'
  import VueDropzone from 'vue2-dropzone'
  import Treeselect from '@riophae/vue-treeselect'
  import moment from 'moment' 
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import {
      apiUrl
  } from '../../constants/config'
  import axios from 'axios'
  import { mapActions, mapGetters  } from 'vuex'
  const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  export default {
      name: "addMessageModal",
      props: {    
          showeDisscussionModal:{
              type: Boolean,
              default: false
          },
          id:{
              type:String,
              required: true
          },
          selectedItem :{
              type: Object, 
              default: null
          },       
          vendeur:{
              type : Boolean,
              default: false
          },
          notification:{
              type : Boolean,
              default: false
          },
          document:{
              type: Object,
              default: null
          },
          is_checkable:{
              type : Boolean,
              default: false
          },
          iframeComponent: {
             type: Boolean,
             default: false
          }
      },
      components: {
          Treeselect,
          DatePicker
      },
      data() {
          return {
              
              wrongEmail: false,
              showMailsContainer:false,
              docsTab: [],
              selectedUserId: null,
              submitted: false,
              selectedRecipient: '',
              msgForm: {  
                email: "",
                  destinataire: null,
                  destinataire_type: null,
                  commande_nbr: null,
                  title: '',
                  commandes: null,
                  message: '',
                  priority: 4,              
                  end_publication_date:  null,
                  start_publication_date: null,   
                  documents: null,     
                  email_copy:false,
                  files: [],
                  file: null,
                  author:"",
                  mails:[]
              },            
              foldersList: [],
              create_message_loader:false,
              labels: [],
              destinatairesTreeSelectOptions: [],
              documentsList: [],
              clientsList:[],
              collabsList:[],
              destinatairesTypesOptions: [
                  { id: '2', label: 'Clients' },
                  { id: '3', label: 'Collaborateurs' },
                  { id: '4', label: 'Externe' },
              ],
              disableTitle:false,
              normalizer(node) {
                  return {
                      id: node.id,
                      label: node.name,
                  }
              },
              normalizerFolders(node) {
                  return {
                      id: node.id,
                      label: node.numero,
                  }
              },
              normalizerDocs(node) {
                  return {
                      id: node.id,
                      label: node.ref_document?node.ref_document.info_aide:node.info_aide,
                  }
              }
          };
      },
      mixins: [validationMixin],
      validations: {
          msgForm: {
              title: {
                  required,
              },
              message: {
                  required,
              },
              email:{
                  email,
                   required: requiredIf(function (nestedModel) {
                      return this.msgForm.destinataire_type =='4' &&this.msgForm.mails.length ==0
                  }),
                
              },
              destinataire_type:{
                  required: requiredIf(function (nestedModel) {
                      return this.selectedItem == null
                  }),
              },
              destinataire: {
                 required: requiredIf(function (nestedModel) {
                      return this.msgForm.destinataire_type !='4'
                  }),
              },           
              end_publication_date: {
                  required
              },
              start_publication_date:{
                  required
              },
              priority:{
                  required
              }
          },
      },  
      methods: {
          isEmailValid() {
           this.wrongEmail = (emailRe.test(this.msgForm.email) && this.msgForm.email!='')?false:(!emailRe.test(this.msgForm.email))?true:'';
          },
          showModal() {            
                this.$bvModal.show(this.id);       
                this.getLabelsList()
                this.wrongEmail = false;
                },
          hideModal() {
              this.reinitData()
              this.$emit('closeModal');          
              this.$bvModal.hide(this.id)
          },  
          editCollabType(){
              this.msgForm.destinataire = null
              this.msgForm.documents = null
              this.msgForm.commandes = null;  
              this.setCurrentTitleDiscussion(null,null)
              if(this.msgForm.destinataire_type==2){
                  this.showMailsContainer = false
                  this.msgForm.mails=[]
                  this.destinatairesTreeSelectOptions = [...this.clientsList]
              }
              if(this.msgForm.destinataire_type==3){
                  this.showMailsContainer = false
                 this.msgForm.mails=[]
                  this.destinatairesTreeSelectOptions = [...this.collabsList]
              }
              if(this.msgForm.destinataire_type==4){
                this.showMailsContainer = true
              }
          },
          getCollabFolders(){               
              this.msgForm.commandes = null;              
              this.msgForm.documents = null
              this.msgForm.mails=[];
              this.msgForm.email="";
              this.setCurrentTitleDiscussion(null,null)
              if(this.msgForm.destinataire_type==3 ){
                  if(this.msgForm.destinataire.length == 1){
                      this.getfoldersList(this.msgForm.destinataire[0])
                  }    
                  else {
                      this.foldersList = []                    
                  }            
              }
              else{
                  if(this.msgForm.destinataire)
                      this.getfoldersList(this.msgForm.destinataire)
                  else
                      this.foldersList = []                
              }
          },   
          getFolderDocs()  {          
              if(this.msgForm.commandes){
                  this.setCurrentTitleDiscussion(this.msgForm.commandes, null) 
                  this.getdocsList(this.msgForm.commandes)  
              }       
          },
          addMail(mail){
            if(this.msgForm.mails.indexOf(mail)<0){
            this.msgForm.mails.push(mail)  
            }
            this.msgForm.email="";
          },
          removeMail(mail){
            let mailIndex=  this.msgForm.mails.indexOf(mail);
            this.msgForm.mails.splice(mailIndex, 1)
          },
          addMessage() {
              this.create_message_loader = true;
              this.submitted = true            
              this.$v.msgForm.$touch()
              if (!this.$v.msgForm.$invalid) {
                let msg = new FormData()
                msg.append( "file" , this.msgForm.file);
                msg.append("title", this.msgForm.title);
                msg.append("content", this.msgForm.message);
                msg.append("start_publication_at", this.msgForm.start_publication_date);
                msg.append( "end_publication_at" ,this.msgForm.end_publication_date);
                msg.append( "label_id",this.msgForm.priority);
                (this.msgForm.commandes != null)?msg.append("num_dossier",this.msgForm.commandes):'';
                if(this.is_checkable==true){
                         msg.append( "is_checkable", true);
                }
                 msg.append( "users_uuids[0]", this.msgForm.destinataire);
                msg.append( "users_uuids[1]",this.currentUser.id);
                msg.append( "is_published", false);
                msg.append( "email_copy", this.msgForm.email_copy);
                (this.msgForm.documents != null)?msg.append( "num_document", this.msgForm.documents):'';
                (this.msgForm.destinataire_type =='4')? msg.append( "external_mails", this.msgForm.mails):'';
                this.loadingSave = true
                  axios.post(apiUrl + '/api/admin/v1/messages', msg)
                  .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                         this.create_message_loader = false;
                          this.$notify('success', this.$t('wording.msgAdded'), '', {
                              duration: 5000,
                              permanent: false,
                          })
                          this.hideModal();
                          if(this.id== 'reason-refus-modal' && this.iframeComponent){
                            this.$emit("createdMessage", true);
                        } 
                            else {
                           this.$store.commit('SET_MESSAGE_CREATED', true); 
                        }                   
                    }
                  })
                  .catch((error) => {
                     this.create_message_loader =false;
                       this.$notify('error', this.$t('Parametrages.alerts.FaildMessageCreation'), error.status, {
                              duration: 5000,
                              permanent: false,
                          })
                    
                    console.log(error)})
              } 
              else {
                this.create_message_loader = false
              }
          },
          scrollToTop() {
              window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
              })
          },
          formatter(value) {
              return value.toLowerCase()
          }, 
          getLabelsList() {
              axios
              .post(apiUrl + '/api/admin/v1/labels')
              .then((response) => {
                  this.labels = response.data.data
                  // this.msgForm.priority=[3]
              })
              .catch((error) => console.log(error))
          },
          getdocsList(selected_cmd) {        
              this.getDocumentsList({
                  dossier_id: selected_cmd,           
              })
          },
          getDocumentsList(params) {     
               axios
              .get(apiUrl + '/api/admin/v1/documents', {
              params,
              })
              .then((response) => {
              this.documentsList = response.data.data
              })
          },
          getClientsList() {
              axios.get(apiUrl + '/api/admin/v1/get-users', {
                  params: {
                      type: 'clients',
                  },
              })
              .then((response) => {
              this.clientsList = this.buildOptionsUsers(response.data.data)
              })
              .catch((error) => console.log(error))
          },
          getCollabsList() {
              axios
              .get(apiUrl + '/api/admin/v1/get-users', {
              params: {
                  type: 'collaborateurs',
              },
              })
              .then((response) => {
              this.collabsList = this.buildOptionsUsers(response.data.data)
              })
              .catch((error) => console.log(error))
          },
          getfoldersList(id) {
              axios
                  .get(apiUrl + '/api/admin/v1/folders', {
                  params: {
                      user_id: id,
                  },
                  })
                  .then((response) => {
                      this.foldersList = response.data.data
                  })
          },
          buildOptionsUsers(data) {
              return data.map((elem) => {
                  return {
                  id: elem.id ? elem.id : elem.uuid,
                  label: elem.prenom ? elem.prenom : (elem.first_name && elem.last_name ? elem.first_name +' '+elem.last_name : elem.first_name),
                  }
              })
          },
          setCurrentTitleDiscussion(selected_cmd, selected_Docs) {
              if (selected_cmd) {
                  this.disableTitle = true
                  if (selected_Docs != null) {
                  this.msgForm.title = this.id == 'reason-refus-modal' ? `Refus du dossier ${this.getCommandeLibele(selected_cmd)} / ${this.getSelectedDocs(selected_Docs)}` :
                      'Dossier ' +
                      this.getCommandeLibele(selected_cmd) +
                      ' / ' +
                      this.getSelectedDocs(selected_Docs)
                  } 
                  else {
                      this.msgForm.title = this.id == 'reason-refus-modal' ? `Refus du dossier ${this.getCommandeLibele(selected_cmd)}` : 'Dossier ' + this.getCommandeLibele(selected_cmd);
                      this.msgForm.documents = null
                  }
              } 
              else {
                  this.disableTitle = false
                  this.msgForm.title = ''
              }
          },
          getCommandeLibele(cmd_id) {
              let commande = this.foldersList.filter((cmd) => cmd_id === cmd.id)
              return commande[0].numero
          },
          getSelectedDocs(docs_list) {
              let that = this
              let doc = that.documentsList.filter((cmd) => cmd.id === docs_list)
              return doc[0].ref_document?doc[0].ref_document.info_aide:doc[0].info_aide
          },
          updateEndDate(){
              let result = moment(this.msgForm.start_publication_date).add(7, 'days').format('YYYY-MM-DD');     
              this.msgForm.end_publication_date = result;  
          },
          reinitData(){
              this.destinatairesTreeSelectOptions = [];
              this.foldersList=[],
              this.msgForm = {
                  mails:[],
                  file:null,
                  destinataire: null,
                  commande_nbr: null,
                  title: '',
                  commandes: null,
                  message: '',
                  priority: null,              
                  end_publication_date: null,
                  start_publication_date: null,        
              }
              this.documentsList = [];
              this.disableTitle = false
              this.$v.$reset()
          }
      },
      watch: {
          showeDisscussionModal: function (value) {
              if (value == true) {
                  this.showModal()
              }
          },
          selectedItem: function(value){
              if(value){   
                  this.msgForm.start_publication_date = moment().format('YYYY-MM-DD');
                  this.msgForm.end_publication_date = moment().add(7, 'days').format('YYYY-MM-DD');
                  this.msgForm.author = this.currentUser.first_name;
                  this.msgForm.priority = 4;
                  this.foldersList=  [this.selectedItem];
                  this.msgForm.commandes = this.selectedItem.id
                  let client = null
                  if(this.selectedItem.client.user_id || this.selectedItem.client.user){
                      client = {
                          id: this.selectedItem.client.user_id ? this.selectedItem.client.user_id : this.selectedItem.client.user.id,
                          label: this.selectedItem.client.nom_prenom ? this.selectedItem.client.nom_prenom : this.selectedItem.client.prenom+' '+this.selectedItem.client.nom                   
                      }
                  }   
                  if(this.vendeur){
                    this.msgForm.destinataire = value.user.id;
                  } 
                  let user = {
                      id: this.selectedItem.user.id,
                      label: this.selectedItem.user.first_name+' '+this.selectedItem.user.last_name
                  } 
                  if(client)
                  this.destinatairesTreeSelectOptions = [client, user]
                  else
                  this.destinatairesTreeSelectOptions = [user]               
                  if(this.document == null){
                      this.getdocsList(this.selectedItem.id)   
                      this.setCurrentTitleDiscussion(this.msgForm.commandes, null)
                  }    
              }            
          },      
          document: function(value){
              if(value!=null){    
                  this.documentsList = [this.document] 
                  this.msgForm.documents = this.document.id
                  this.msgForm.file=  this.document.doc_name
                  this.setCurrentTitleDiscussion(this.msgForm.commandes, this.document.id)
              }
          },
          notification: function(value){
              if(value){
                  this.msgForm.author = this.currentUser.first_name;  
                  this.msgForm.start_publication_date = moment().format('YYYY-MM-DD');
                  this.msgForm.end_publication_date = moment().add(7, 'days').format('YYYY-MM-DD');
                  this.msgForm.priority = 4;   
                  this.getClientsList()
                  this.getCollabsList()             
              }
          }
      },
      computed:{ 
          ...mapGetters(['currentUser']),
          disabledBeforeStart(){
              return moment().format('YYYY-MM-DD')
          },  
          disabledBeforeEnd(){
              if(this.msgForm.start_publication_date){    
                  let result = moment(this.msgForm.start_publication_date).format('YYYY-MM-DD');   
                  return result
              } 
          }
      },
  }
  </script>
  <style scoped lang="scss">
  .__add-email-icon{
     &:hover{
       background:#151635;
         i{
            color:white !important
            }
        }
        width: 4px;
        border: 1px solid #151635;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
        color: #151635;
        font-size:19px;
        }
    &.disabled{
        pointer-events: none;
        border: 1px solid #e5e8ed;
        i{
        color: #e5e8ed;
        font-size:19px
        }
    }
  }
  .__mail-input{
    padding-right:2px
  }
  .__mail::placeholder {
    font-size:10px;
}
._mails-containers{
    border: 1px solid #e5e8ed;
    margin: 0 0 15px 15px;
    min-height: 57px; 
    padding:5px;
    .badge {
      border-radius:0;
      padding:2px;
      margin:2px;
      background:#6c757d;
      border:1px solid #6c757d;
      overflow: hidden;
      text-overflow: clip;
    }
    i{
     cursor: pointer;
     color:white;
     background:#6c757d;
     padding:0;
     font-size:12px
   }
  }
._alet-warning{
    color: #d61523; 
    position: absolute;
    font-size: 11px;
    display:flex;
    align-items:center;
    min-height: 14px;
  }
  </style>
  