import { render, staticRenderFns } from "./cmd-create-message-modal.vue?vue&type=template&id=3760329b&scoped=true"
import script from "./cmd-create-message-modal.vue?vue&type=script&lang=js"
export * from "./cmd-create-message-modal.vue?vue&type=script&lang=js"
import style0 from "./cmd-create-message-modal.vue?vue&type=style&index=0&id=3760329b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3760329b",
  null
  
)

export default component.exports