<template>
  <b-navbar
    id="template-header"
    class="p-0 fixed-top d-flex flex-row"
    toggleable="lg"
  >
    <div
      class="navbar-brand-wrapper d-flex d-sm-none align-items-center justify-content-center"
    >
      <router-link class="navbar-brand brand-logo-mini" to="/">
        <img src="@/assets/images/logo-mini.svg" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button
        id="menu-mobile-toggle"
        class="navbar-toggler navbar-toggler align-self-center"
        type="button"
        @click="toggleSidebar()"
      >
        <span class="feather-menu"></span>
      </button>
      <router-link class="header-brand header-logo" to="/">
        <img src="@/assets/images/logo-red.svg" alt="logo" />
      </router-link>
      <!-- recherche -->
      <!-- <ul class="navbar-nav w-100  d-sm-block">
            <li class="nav-item nav-item-search">
                <form class="nav-link mt-2 mt-md-0  d-flex search">
                    <div class="search-icon"><i class="feather-search"></i></div>
                    <input type="text" class="form-control" :placeholder="$t('wording.rechercher')" />
                </form>
            </li>
        </ul>     -->
      <b-navbar-nav class="navbar-nav-right ml-auto">
        <!-- notification -->
        <b-nav-item-dropdown
          v-if="all_notifications.length"
          class="align-items-center justify-content-center notification-nav"
          :id="'notifications-dropdown'"
        >
          <template slot="button-content">
            <ul
              class="navbar-nav w-100 position-relative notifications-item"
              @click="toggleTarget"
            >
              <!-- <span >Message</span> -->
              <!-- <img class="img-xs img-notification " src="@/assets/images/dashboard/notifications.svg" alt="Message" /> -->
              <i class="ri-discuss-line notif-icon"></i>
              <span v-if="notifications_count" class="navbar-notifications">{{
                  notifications_count
              }}</span>
            </ul>
          </template>

          <b-dropdown-item>
            <div
              class="d-flex align-items-center justify-content-between w-100 message-header"
            >
              <h3 class="mb-0">{{ $t("wording.messages") }}</h3>
              <span @click="seeAllNotfication">{{
                $t("wording.readNotifs")
              }}</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="currentUser != null && !currentUser.client">
            <div
              class="d-flex align-items-center justify-content-between w-100 message-header"
            >
              <button
                tag="button"
                @click="addMessage"
                class="mb-0 btn p-0 btn-ad-message"
              >
                {{ $t("wording.nv-message") }}
              </button>
            </div>
          </b-dropdown-item>
          <b-dropdown-item
            v-for="(item, index) in all_notifications"
            :key="index"
            :class="!item.is_read ? 'unread-bg' : ''"
          >
            <div
              class="message-item"
              @click="
                ViewItem($event, item, 'modalGlobal-' + index + '-notification')
              "
            >
              <div class="icon">
                <span
                  v-if="item.answers[item.answers.length - 1].user.first_name"
                  >{{
                    item.answers[item.answers.length - 1].user.first_name
                      | firstLetter
                  }}</span
                >
              </div>
              <div class="content">
                <h3>
                  {{ item.answers[item.answers.length - 1].user.first_name }}
                  {{ item.answers[item.answers.length - 1].user.last_name }}
                </h3>
                <span class="date">{{
                  item.answers[item.answers.length - 1].updated_at
                    | formatDateTime
                }}</span>
                <span v-if="item.folder.numero" class="cmd"
                  ><span class="cmd-num">{{ item.folder.numero }}</span
                  ><i class="ri-external-link-line cmd-icon"></i
                ></span>
                <p>
                  {{
                    truncate(item.answers[item.answers.length - 1].content, 50)
                  }}
                </p>
              </div>
            </div>
            <global-discussion-2
              :index="index + '-notification'"
              :msgId="item.message_id"
              :parentId="item.answers[0].id"
              :title="item.title"
              :document="null"
              :refreshComments="refreshComments"
              @refresh="refresh"
              :selectedModal="selectedModal"
              @reinitRefresh="reinitRefresh"
            ></global-discussion-2>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-else
          class="align-items-center justify-content-center notification-nav"
          :id="'notifications-dropdown'"
        >
          <template slot="button-content">
            <ul
              class="navbar-nav w-100 position-relative notifications-item"
              @click="toggleTarget"
            >
              <!-- <span >{{$t('wording.notif')}}</span> -->
              <i class="ri-discuss-line notif-icon"></i>
            </ul>
          </template>
          <b-dropdown-item>
            <div
              class="d-flex align-items-center justify-content-between w-100 message-header"
            >
              <h3 class="mb-0">{{ $t("wording.notifs") }}</h3>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="currentUser != null && !currentUser.client">
            <div
              class="d-flex align-items-center justify-content-between w-100 message-header"
            >
              <button
                tag="button"
                @click="addMessage"
                class="mb-0 btn p-0 btn-ad-message"
              >
                {{ $t("wording.nv-message") }}
              </button>
            </div>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- ALERTS -->

        <LanguageSwitch />
        <!-- Language Switch -->
        <b-nav-item right class="preview-list nav-profile d-sm-flex">
          <router-link
            :to="{ name: 'editProfile' }"
            class="navbar-profile"
            tag="div"
          >
            <img
              v-if="currentUser"
              class="img-xs rounded-circle border-img"
              src="@/assets/images/faces-clipart/pic-1.png"
              alt=""
            />
            <div class="d-grid">
              <span v-if="currentUser" class="navbar-profile-name">
                {{ currentUser.last_name }} {{ currentUser.first_name }}
              </span>
              <span
                class="navbar-profile-name _user--function"
                v-if="currentUser"
                >{{ currentUser.fonction.fonction_lib }}</span
              >
            </div>
          </router-link>
          <div></div>
        </b-nav-item>
        <b-dropdown
          size="lg"
          right
          variant="link"
          toggle-class="text-decoration-none d-sm-flex"
          no-caret
          v-if="isAdmin"
        >
          <template #button-content>
            <i class="ri-arrow-down-s-line"></i>
          </template>
          <b-dropdown-item href="">
            <router-link
              :to="{ name: 'editProfile' }"
              class="navbar-profile"
              tag="div"
            >
              <span v-if="currentUser" class="navbar-profile-name">
                {{ $t("wording.yourProfil") }}
              </span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item
            href="https://api.easymat.oadsys.net/admin"
            target="_blank"
          >
            {{ $t("wording.admistration") }}
          </b-dropdown-item>
        </b-dropdown>
        <b-nav-item class="nav-logout d-sm-block" @click="logout">
          <i class="feather-log-out"></i>
        </b-nav-item>
      </b-navbar-nav>
      <div class="btn-toggle-mobile d-none" @click="toggleMobile">
        <i class="mdi mdi-dots-vertical float-right"></i>
      </div>
    </div>
    <div
      id="togglemobile"
      class="toggle-mobile navbar-menu-wrapper d-none align-items-center"
    >
      <!-- recherche -->
      <ul class="navbar-nav w-100">
        <li class="nav-item nav-item-search">
          <form class="nav-link mt-2 mt-md-0 d-flex search">
            <div class="search-icon"><i class="feather-search"></i></div>
            <input type="text" class="form-control" placeholder="Search" />
          </form>
        </li>
      </ul>
      <b-navbar-nav class="navbar-nav-right ml-auto">
        <b-nav-item class="nav-logout" @click="logout">
          <i class="feather-log-out"></i>
        </b-nav-item>
      </b-navbar-nav>
    </div>
    <addMessageModal
      :showeDisscussionModal="showDisscussionModal"
      :is_checkable="is_checkable"
      @closeModal="closeModalMessages"
      :selectedItem="selectedFolder"
      :notification="isForNotifications"
      :id="'header-modal'"
    ></addMessageModal>

    <reminder-modal
      :showeDisscussionModal="showModal"

      @closeModal="hideModal"
      :isChecked="true"
      :folderDetails="SelectedItem"
      :isFromCmdDetails="isFromCmdDetails"
      :isEdit="isEdit"
      :SelectedItem="SelectedItem"
      :labels="actionslab"
      @refresh="refreshReminder"
      @AppendMessage="$emit('refeshPlanDaction')"
      :id="'todo-list-modal-s'"
    ></reminder-modal>
  </b-navbar>
</template>

<script>
import addMessageModal from "../../components/planning/cmd-create-message-modal.vue";
import LanguageSwitch from '../../components/LanguageSwitch.vue';
import reminderModal from "../../components/planning/actionsModal.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import GlobalDiscussion2 from "../../components/discussion/GlobalDiscussion2.vue";
import { onMessage } from "firebase/messaging";
import messaging from "../../main";

export default {
  name: "app-header",
  components: {
    GlobalDiscussion2,
    addMessageModal,
    LanguageSwitch,
    "reminder-modal": reminderModal,
  },
  data() {
    return {
      target: true,
      targetReminders: true,
      showModal: false,
      fcmChannel: null,
      isFromCmdDetails: false,
      isEdit: false,
      modalOpen: false,
      selectedModal: null,
      SelectedItem: null,
      refreshComments: false,
      showDisscussionModal: false,
      isForNotifications: false,
      currentRoute: null,
      isForSelectedFolder: false,
      selectedFolder: null,
      is_checkable: false,
      actionslab: [
        {
          color: "#E64F3B",
          id: 1,
          name: this.$t("actionsStatusLabs.alert"),
        },
        {
          color: "#FE9318",
          id: 2,
          name: this.$t("actionsStatusLabs.important"),
        },
        {
          color: "#54B445",
          id: 3,
          name: this.$t("actionsStatusLabs.noraml"),
        },
        {
          color: "#DEDFE6",
          id: 4,
          name: this.$t("actionsStatusLabs.PourInfo"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      notificationsCount: "notificationsCount",
      allNotifications: "allNotifications",
      remindersCount: "remindersCount",
      allReminders: "allReminders",
      loggedOut: "loggedOut",
    }),

    notifications_count() {
      if (this.notificationsCount['default']) {
        return  this.notificationsCount['default'];
      }

      return null;
    },
    all_notifications() {
      if (this.allNotifications['default']) {
        return  this.allNotifications['default'];
      }

      return [];
    },
    all_reminders() {
      if (this.allReminders['default']) {
        return  this.allReminders['default'];
      }

      return [];
    },

    isClient() {
      return this.$store.getters.currentUser.fonction.id == 4 ? true : false;
    },
    isAdmin() {
      return this.$store.getters.currentUser?.fonction?.id == 5 ? true : false;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeSideBar);
    try {
      if ("serviceWorker" in navigator) {
        console.log("serviceWorker in navigator");
        navigator.serviceWorker
          .register("/firebase-messaging-sw.js")
          .then((registration) => {
            console.log(
              "Registration successful, scope is: ",
              registration.scope
            );
          });
      }
      if (messaging) {
        onMessage(messaging, (payload) => {
          console.log("new notification", payload);
          this.getNotifications();
          this.refreshCommentsMethod();
          if (
            this.currentUser &&
            this.currentUser != null &&
            this.currentUser.client
          ) {
            this.updateMessages();
          }
          if ("serviceWorker" in navigator) {
            navigator.serviceWorker
              .getRegistration("./firebase-messaging-sw.js")
              .then((registration) => {
                const notificationTitle = payload.notification?.title
                  ? payload.notification.title
                  : "Easymat";
                const notificationOptions = {
                  body: payload.notification?.body
                    ? payload.notification.body
                    : "Vous avez un message non lu",
                };
                registration.showNotification(
                  notificationTitle,
                  notificationOptions
                );
              })
              .catch((error) => {
                console.log("No service worker registered", error);
              });
          }
        });
      }
    } catch (err) {
      console.log(
        "Ce navigateur ne prend pas en charge les API requises pour utiliser le SDK Firebase"
      );
    }
    try {
      this.fcmChannel = new BroadcastChannel("fcm-channel");
      this.fcmChannel.onmessage = (event) => {
        if (event.data == "reload") {
          console.log("new notification in background");
          this.getNotifications();
          this.refreshCommentsMethod();
          if (
            this.currentUser &&
            this.currentUser != null &&
            this.currentUser.client
          ) {
            this.updateMessages();
          }
        }
      };
    } catch (err) {
      console.log(
        "Ce navigateur ne prend pas en charge les API requises pour utiliser le SDK Firebase"
      );
    }
  },

  async beforeDestroy() {
    document.removeEventListener("click", this.closeSideBar);
    await this.fcmChannel.close();
  },
  created() {
    this.currentRoute = this.$route;
    document.querySelector("body").classList.add("sidebar-icon-only");
    this.getNotifications();
    //    this.getNotifications({is_checkable :true})
  },
  methods: {
    ...mapActions([
      "signOut",
      "getNotifications",
      "viewCheckItem",
      "checkItem",
      "viewItem",
      "seeAll",
      "sendMessageFirebase",
      "getFcmFirebase",
      "updateMessages",
    ]),
    ...mapMutations(["setLoggedOut"]),
    refresh() {
      this.getNotifications();
      this.refreshComments = false;
      this.modalOpen = false;
      this.selectedModal = null;
    },
    refreshReminder(status, item) {
      console.log("item", item)
            console.log("status", status)
        const reminderItem = this.all_reminders.find(elt => elt == item)
        if(reminderItem){ reminderItem.is_read = status==true?true:false;}

      this.viewCheckItem({
        message_id: item.message_id,
        status: !item.is_read,
      });
      this.checkItem({
        message: item.message_id,
        checked: this.checked,
      });
    },
    hideModal() {
      this.isFromCmdDetails = false;
      this.isEdit = false;
      this.SelectedItem = false;
      this.showModal = false;
    },
    logout() {
      const user = localStorage.getItem("user");
      localStorage.removeItem("Ocr_cr");
      this.signOut();
    },
    toggleSidebar: () => {
      document.querySelector("body").classList.toggle("sidebar-icon-only");
      // if (window.innerWidth <= 600) {
      //   if( document.querySelector('body').classList.contains('sidebar-icon-only')){
      //     if(document.querySelector('#togglemobile').classList.contains('active')){
      //       document.querySelector('#togglemobile').classList.toggle('active');
      //     }
      //   }
      // }
    },
    closeSideBar(e) {
      if (window.innerWidth <= 600) {
        if (
          !document
            .querySelector("body")
            .classList.contains("sidebar-icon-only")
        ) {
          if (
            e.target.id != "menu-mobile-toggle" &&
            !e.target.closest("#menu-mobile-toggle")
          ) {
            let parent = e.target.parentNode;
            if (
              !parent.closest("#sidebar") &&
              !parent.classList.contains("app-sidebar")
            ) {
              this.toggleSidebar();
            }
          }
        }
      }
    },
    toggleMobile: () => {
      document.querySelector("#togglemobile").classList.toggle("active");
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    ViewItem(event, item, id) {
      if (
        (event.target.className.includes("cmd-num") ||
          event.target.className.includes("cmd-icon")) &&
        !this.currentUser.client
      ) {
        if (item.folder.numero) {
          let routeData = this.$router.resolve({
            name:
              this.currentUser.profil.id === "3"
                ? "showCommandeDetails"
                : "showNewCommandesDetails",
            params: {
              num_cmd: item.folder.numero,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        this.viewItem({ message_id: item.message_id, status: true });
        this.modalOpen = true;
        this.is_checkable = false;
        this.selectedModal = item.message_id;
        this.$bvModal.show(id);
      }
    },
    ViewReminderItem(event, item, id) {
      if (
        (event.target.className.includes("cmd-num") ||
          event.target.className.includes("cmd-icon")) &&
        !this.currentUser.client
      ) {
        if (item.folder?.numero) {
          let routeData = this.$router.resolve({
            name:
              this.currentUser.profil.id === "3"
                ? "showCommandeDetails"
                : "showNewCommandesDetails",
            params: {
              num_cmd: item.folder.numero,
            },
          });
          window.open(routeData.href, "_blank");
        }
      } else {
        this.isFromCmdDetails = true;
        this.isEdit = true;
        this.SelectedItem = item;
        this.$nextTick(() => {
          this.showModal = true;
        });
      }
    },
    async seeAllNotfication() {
      await this.seeAll();
      this.getNotifications();
    },
    toggleTarget() {
      this.target = !this.target;
    },
    toggleRemindersToggle() {
      this.targetReminders = !this.targetReminders;
    },
    refreshCommentsMethod() {
      if (this.modalOpen) {
        this.refreshComments = true;
      }
    },
    reinitRefresh() {
      this.refreshComments = false;
    },
    addMessage(is_checkable) {
      this.showDisscussionModal = true;
      this.is_checkable = is_checkable  == true? is_checkable : false;
      if (["showCommandeDetails", "showCommande"].includes(this.$route.name)) {
        this.isForNotifications = false;
        this.selectedFolder = this.$store.getters.folder;
      } else {
        this.isForNotifications = true;
      }
    },
    closeModalMessages() {
      this.showDisscussionModal = false;
      this.isForNotifications = false;
      this.selectedFolder = null;
    },
  },
  watch: {
    "$store.getters.folder": {
      immediate: true,
      deep: true,
      handler(folder) {
        if (
          ["showCommandeDetails", "showCommande"].includes(this.$route.name)
        ) {
          this.isForSelectedFolder = true;
          this.selectedFolder = folder;
          this.isForNotifications = false;
        } else {
          this.isForSelectedFolder = false;
        }
      },
    },
    allNotifications: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data.length == 0) {
          this.target = true;
        }
      },
    },
    allReminders: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data.length == 0) {
          this.targetReminders = true;
        }
      },
    },
    loggedOut: function (value) {
      if (value == true) {
        this.$router.push({ name: "login" });
        this.setLoggedOut(false);
      }
    },
  },
};
</script>
