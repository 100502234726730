import Enumeration from './Enumeration';

const apiErrorsStatus = new Enumeration({
     500:"Probléme serveur.",
     404:"Les données demandées n'existent pas.",
     401:"L'accès non autorisé.",
     403:"Accès interdit.",
     409 :"Données introuvables."
});

export default apiErrorsStatus;